import React from 'react';

interface AnimatedWaveBackgroundProps {
  isDark: boolean;
}

const AnimatedWaveBackground: React.FC<AnimatedWaveBackgroundProps> = ({ isDark }) => {
  const styles = `
    @keyframes wave {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-50%);
      }
    }
    .wave {
      animation: wave 50s linear infinite;
      width: 200%;
    }
  `;

  return (
    <div className="absolute inset-0 overflow-hidden">
      <style>{styles}</style>
      <svg
        className="absolute bottom-0 left-0 w-full h-auto wave"
        style={{ minHeight: '230px' }}
        viewBox="0 0 1440 230"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stopColor={isDark ? "rgba(75, 0, 130, 1)" : "rgba(193.125, 62, 243, 1)"} offset="0%"></stop>
            <stop stopColor={isDark ? "rgba(60, 0, 100, 1)" : "rgba(165.875, 117.832, 199.408, 1)"} offset="100%"></stop>
          </linearGradient>
        </defs>
        <path 
          fill="url(#sw-gradient-0)" 
          d="M0,165 C120,150 180,90 240,90 C300,90 360,150 420,165 C480,180 540,150 600,135 C660,120 720,120 780,135 C840,150 900,180 960,180 C1020,180 1080,150 1140,135 C1200,120 1260,120 1320,135 C1380,150 1440,180 1440,165 L1440,330 L0,330 Z"
        ></path>
      </svg>
    </div>
  );
};

export default AnimatedWaveBackground;

import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

type SupportedLanguage = 'en' | 'fr';

interface LanguageContextType {
  language: SupportedLanguage;
  setLanguage: (lang: SupportedLanguage) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

const SUPPORTED_LANGUAGES: SupportedLanguage[] = ['en', 'fr'];

const getSystemLanguage = (): SupportedLanguage => {
  // Afficher toutes les langues disponibles pour le débogage
  console.log('Navigator languages:', navigator.languages);
  console.log('Navigator language:', navigator.language);

  // Récupérer la langue du navigateur
  const browserLang = navigator.language.toLowerCase();
  console.log('Browser language detected:', browserLang);

  // Vérifier d'abord la correspondance exacte
  if (browserLang === 'fr' || browserLang.startsWith('fr-')) {
    console.log('French language detected');
    return 'fr';
  }

  if (browserLang === 'en' || browserLang.startsWith('en-')) {
    console.log('English language detected');
    return 'en';
  }

  // Si aucune correspondance n'est trouvée, parcourir les langues préférées
  const userLanguages = navigator.languages || [navigator.language];
  for (const lang of userLanguages) {
    const simpleLang = lang.toLowerCase().split('-')[0];
    console.log('Checking language:', simpleLang);
    
    if (simpleLang === 'fr') {
      console.log('French found in language list');
      return 'fr';
    }
  }

  // Par défaut retourner l'anglais
  console.log('No supported language found, defaulting to English');
  return 'en';
};

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguageState] = useState<SupportedLanguage>(() => {
    // Vérifier d'abord les préférences sauvegardées
    const savedLang = localStorage.getItem('userLanguage') as SupportedLanguage | null;
    console.log('Saved language:', savedLang);

    if (savedLang && SUPPORTED_LANGUAGES.includes(savedLang)) {
      console.log('Using saved language:', savedLang);
      return savedLang;
    }

    // Sinon utiliser la langue du système
    const systemLang = getSystemLanguage();
    console.log('Using system language:', systemLang);
    return systemLang;
  });

  const setLanguage = (newLanguage: SupportedLanguage) => {
    console.log('Setting new language:', newLanguage);
    if (SUPPORTED_LANGUAGES.includes(newLanguage)) {
      setLanguageState(newLanguage);
      localStorage.setItem('userLanguage', newLanguage);
    }
  };

  // Mettre à jour la langue du document
  useEffect(() => {
    document.documentElement.lang = language;
    console.log('Document language set to:', language);
  }, [language]);

  // Ajouter un effet pour vérifier la langue au démarrage
  useEffect(() => {
    const checkLanguage = () => {
      const systemLang = getSystemLanguage();
      if (!localStorage.getItem('userLanguage') && systemLang !== language) {
        console.log('Updating to system language:', systemLang);
        setLanguage(systemLang);
      }
    };

    checkLanguage();
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

// Utilitaire pour vérifier si une langue est supportée
export const isSupportedLanguage = (lang: string): lang is SupportedLanguage => {
  return SUPPORTED_LANGUAGES.includes(lang as SupportedLanguage);
};

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from './ThemeContext';
import { LanguageProvider } from './LanguageContext';
import LoginPage from './components/LoginPage';
import AboutUs from './components/About';
import Contact from './components/Contact';
import LunaSpaceDrive from './components/LunaSpaceDrive';
import Settings from './components/Settings';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const isAuthenticated = sessionStorage.getItem('userKey') !== null;
  return isAuthenticated ? <>{children}</> : <Navigate to="/" replace />;
};

const RedirectIfAuthenticated = ({ children }: { children: React.ReactNode }) => {
  const isAuthenticated = sessionStorage.getItem('userKey') !== null;
  return isAuthenticated ? <Navigate to="/me" replace /> : <>{children}</>;
};

function App() {
  return (
    <ThemeProvider>
      <LanguageProvider>
        <Router>
          <Routes>
            <Route 
              path="/" 
              element={
                <RedirectIfAuthenticated>
                  <LoginPage />
                </RedirectIfAuthenticated>
              } 
            />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/settings" element={<Settings />} />
            <Route
              path="/me"
              element={
                <ProtectedRoute>
                  <LunaSpaceDrive />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;

import React, { useState, useCallback, useEffect, useMemo, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { 
  Edit2, 
  Folder,
  Gem,
  File, 
  Trash, 
  Download, 
  Image, 
  FileText, 
  FileSpreadsheet, 
  Headphones, 
  Clapperboard, 
  FileCode, 
  Presentation, 
  LogOut, 
  Upload,
  Share2,
  Link,
  FileArchive,
  Palette,
  FileCog,
  Database,
  FileJson,
  Figma,
  SquareTerminal
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { 
  check, 
  uploadToDiscord, 
  saveFileInfo, 
  renameFile, 
  deleteFile, 
  downloadFile, 
  formatSize,
  createShareLink,
  importSharedFile,
  canShareFile,
  canDownloadFile,
  removeImportedFile,
  getPreferences,
  updatePreferences
} from './Api';
import { useTheme } from '../ThemeContext';
import { useLanguage } from '../LanguageContext';
import FileUpload from './FileUpload';
import Navbar from './Navbar';

// Types
interface ThemeContextType {
  theme: string;
  toggleTheme: () => void;
}

interface LanguageContextType {
  language: string;
  setLanguage: (lang: string) => void;
}

interface FileImportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFileUpload: (file: File, onProgress?: (progress: number) => void) => Promise<void>;  // Make onProgress optional
  isDark: boolean;
  t: any;
  onRefresh: () => Promise<void>;
}

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  shareLink: string;
  isDark: boolean;
}

interface FileGridItemProps {
  file: FileItem;
  isDark: boolean;
  onShare: (fileId: string) => void;
}


interface FileItem {
  uuid: string;
  name: string;
  size: number;
  upload_timestamp?: number;
  is_shared?: boolean;
  is_imported?: boolean;
  original_key?: string;
  original_uuid?: string;
}
interface ContextMenu {
  x: number;
  y: number;
  fileId: string;
}

interface EmptyStateProps {
  onUploadClick: () => void;
  isDark: boolean;
  t: any;
}

interface RenameModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRename: (newName: string) => void;
  currentName: string;
  isDark: boolean;
}

interface DownloadProgress {
  fileId: string;
  progress: number;
  fileName: string;
}

// Ajout des types pour le tri
type SortField = 'name' | 'size' | 'upload_timestamp';
type SortDirection = 'asc' | 'desc';

interface SortConfig {
  field: SortField;
  direction: SortDirection;
}

// Composants
const EmptyState: React.FC<EmptyStateProps> = ({ onUploadClick, isDark, t }) => (
  <div className="flex flex-col items-center justify-center h-full">
    <div className={`p-8 ${isDark ? 'box-night-no-files' : 'box-day-no-files'} rounded-lg shadow-lg text-center`}>
      <Upload className={`mx-auto h-12 w-12 ${isDark ? 'text-gray-400' : 'text-gray-600'}`} />
      <h3 className={`mt-2 text-sm font-semibold ${isDark ? 'text-white' : 'text-gray-900'}`}>
        {t.noFiles}
      </h3>
      <p className={`mt-1 text-sm ${isDark ? 'text-gray-400' : 'text-gray-500'}`}>
        {t.getStarted}
      </p>
      <div className="mt-6">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          onClick={onUploadClick}
        >
          <Upload className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          {t.uploadFile}
        </button>
      </div>
    </div>
  </div>
);

// Fonction utilitaire
const getFileIcon = (fileName: string) => {
  const extension = fileName.split('.').pop()?.toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
    case 'webp':
    case 'tiff':
    case 'ico':
      return <Image className="text-[#1D9BF0]" size={40} />;
    case 'pdf':
    case 'doc':
    case 'docx':
    case 'odt':
    case 'rtf':
    case 'md':
    case 'epub':
      return <FileText className="text-[#F55B20]" size={40} />;
    case 'xls':
    case 'xlsx':
    case 'csv':
    case 'ods':
    case 'tsv':
    case 'numbers':
      return <FileSpreadsheet className="text-[#4CAF50]" size={40} />;
    case 'mp3':
    case 'wav':
    case 'ogg':
    case 'flac':
    case 'aac':
    case 'm4a':
    case 'wma':
    case 'mid':
    case 'midi':
      return <Headphones className="text-[#FFC107]" size={40} />;
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'mkv':
    case 'flv':
    case 'wmv':
    case 'webm':
    case 'mpg':
    case 'mpeg':
    case '3gp':
    case 'vob':
    case 'm4v':
      return <Clapperboard className="text-[#9C27B0]" size={40} />;
    case 'js':
    case 'ts':
    case 'dll':
    case 'py':
    case 'html':
    case 'css':
    case 'go':
    case 'tsx':
    case 'pyw':
    case 'java':
    case 'cpp':
    case 'c':
    case 'php':
    case 'rb':
    case 'xml': 
    case 'yaml':
    case 'yml':
    case 'rs':
    case 'lua':
    case 'scala':
    case 'swift':
      return <FileCode className="text-[#2196F3]" size={40} />;
    case 'pptx':
    case 'ppt':
    case 'odp':
    case 'key': 
    case 'otp':
      return <Presentation className="text-[#FF9800]" size={40} />;
    case 'zip':
    case 'rar':
    case '7z':
    case 'gz':
    case 'tar':
    case 'bz2':
    case 'xz':
    case 'iso':
    case 'dmg':
      return <FileArchive className="text-[#009688]" size={40}/>;
    case 'psd':
    case 'ai':
    case 'xd':
    case 'fig':
    case 'sketch':
    case 'cdr':
    case 'blend':
    case 'obj':
    case 'fbx':
    case 'stl':
    case 'dwg':  
      return <Palette className="text-[#E91E63]" size={40} />;
    case 'txt':
    case 'log':
    case 'ini':
    case 'cfg':
    case 'csv':
      return <FileText className="text-[#9E9E9E]" size={40} />;
    case 'exe':
    case 'msi':
    case 'src':
      return <FileCog className="text-[#FF5722]" size={40} />;
    case 'bat':
    case 'cmd':
    case 'sh':
      return <SquareTerminal className="text-[#607D8B]" size={40} />;
    case 'sql':
      return <Database className="text-[#FF9800]" size={40} />;
    case 'json':
      return <FileJson className="text-[#8E24AA]" size={40} />;
    case 'fig':
      return <Figma className="text-[#3F51B5]" size={40} />;
    case 'lunaspace':
      return <Gem className="text-[#57A5FF]" size={40} />;
    default:
      return <File className="text-gray-400" size={40} />;
  }
};

const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const formatDate = (upload_timestamp?: number) => {
  if (upload_timestamp === undefined) {
    return 'Date inconnue';
  }

  const date = new Date(upload_timestamp * 1000);
  if (isNaN(date.getTime())) {
    return 'Date invalide';
  }

  return date.toLocaleString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const getFileStatusInfo = (file: FileItem) => {
  if (file.is_imported) {
    return {
      text: 'Fichier importé',
      textColor: 'text-blue-500',
      buttonStyle: 'text-blue-500 hover:text-blue-600',
      tooltip: 'Fichier importé'
    };
  }
  if (file.is_shared) {
    return {
      text: 'Fichier partagé',
      textColor: 'text-purple-500',
      buttonStyle: 'text-purple-500 hover:text-purple-600',
      tooltip: 'Fichier déjà partagé'
    };
  }
  return {
    text: '',
    textColor: '',
    buttonStyle: 'text-gray-400 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100',
    tooltip: 'Partager le fichier'
  };
};

const FileGridItem: React.FC<FileGridItemProps> = ({ file, onShare, isDark }) => {
  const statusInfo = getFileStatusInfo(file);
  const currentUserKey = sessionStorage.getItem('userKey');

  return (
    <div className={`relative p-4 ${isDark ? 'bg-gray-700' : 'bg-white'} rounded-lg shadow cursor-pointer hover:shadow-md transition`}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          if (canShareFile(file, currentUserKey)) {
            onShare(file.uuid);
          }
        }}
        disabled={!canShareFile(file, currentUserKey)}
        className={`absolute top-2 right-2 p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-600 
          ${!canShareFile(file, currentUserKey) ? 'cursor-not-allowed opacity-50' : ''}`}
        title={statusInfo.tooltip}
      >
        <Share2 className={`w-4 h-4 ${statusInfo.buttonStyle}`} />
      </button>
      {getFileIcon(file.name)}
      <p className="font-medium" title={file.name}>
        {truncateText(file.name, 30)}
      </p>
      <p className={`text-sm ${isDark ? 'text-gray-300' : 'text-gray-500'}`}>
        {formatSize(file.size)} • {formatDate(file.upload_timestamp)}
      </p>
      {(file.is_shared || file.is_imported) && (
        <div className={`mt-2 text-xs ${statusInfo.textColor}`}>
          {statusInfo.text}
        </div>
      )}
    </div>
  );
};

const FileImportModal: React.FC<FileImportModalProps> = ({
  isOpen,
  onClose,
  onFileUpload,
  isDark,
  t,
  onRefresh
}) => {
  const [activeTab, setActiveTab] = useState<'file' | 'link'>('file');
  const [shareLink, setShareLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleShareLinkImport = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const shareId = shareLink.split('/').pop();
      if (!shareId) {
        throw new Error('Invalid share link');
      }

      await importSharedFile(shareId);
      await onRefresh();
      
      setShareLink('');
      onClose();
      
    } catch (error) {
      console.error('Error importing file:', error);
      setError('Failed to import shared file');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen p-4">
        {/* Overlay */}
        <div className="fixed inset-0 bg-black bg-opacity-30" onClick={onClose} />
        
        {/* Modal */}
        <div className={`relative ${isDark ? 'bg-gray-800' : 'bg-white'} rounded-lg p-6 w-full max-w-md mx-4 shadow-xl`}>
          {/* Tabs */}
          <div className="mb-6">
            <div className="flex border-b border-gray-200">
              <button
                className={`px-4 py-2 ${
                  activeTab === 'file'
                    ? `border-b-2 border-purple-500 ${isDark ? 'text-purple-400' : 'text-purple-600'}`
                    : isDark ? 'text-gray-400' : 'text-gray-500'
                }`}
                onClick={() => setActiveTab('file')}
              >
                {t.uploadFile}
              </button>
              <button
                className={`px-4 py-2 ${
                  activeTab === 'link'
                    ? `border-b-2 border-purple-500 ${isDark ? 'text-purple-400' : 'text-purple-600'}`
                    : isDark ? 'text-gray-400' : 'text-gray-500'
                }`}
                onClick={() => setActiveTab('link')}
              >
                {t.importFile}
              </button>
            </div>
          </div>

          {/* Content */}
          <div className="mt-4">
            {activeTab === 'file' ? (
              <FileUpload onFileUpload={onFileUpload} isDark={isDark} />
            ) : (
              <form onSubmit={handleShareLinkImport} className="space-y-4">
                <input
                  type="text"
                  value={shareLink}
                  onChange={(e) => setShareLink(e.target.value)}
                  placeholder="Enter share link"
                  className={`w-full p-2 rounded border ${
                    isDark
                      ? 'bg-gray-700 text-white border-gray-600'
                      : 'bg-white text-black border-gray-300'
                  }`}
                />
                {error && <p className="text-red-500 text-sm">{error}</p>}
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full py-2 px-4 rounded ${
                    isLoading
                      ? 'bg-gray-500 cursor-not-allowed'
                      : 'bg-purple-600 hover:bg-purple-700'
                  } text-white transition-colors duration-200`}
                >
                  {isLoading ? 'Importing...' : 'Import'}
                </button>
              </form>
            )}
          </div>

          {/* Close button */}
          <button
            onClick={onClose}
            className={`absolute top-2 right-2 p-2 rounded-full ${
              isDark ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
            }`}
          >
            <svg
              className="w-5 h-5"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

const LunaSpaceDrive: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  const { language, setLanguage } = useLanguage();
  const isDark = theme === 'dark';
  const navigate = useNavigate();

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [selectedFileForShare, setSelectedFileForShare] = useState<string | null>(null);

  // États
  const [files, setFiles] = useState<FileItem[]>([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState<ContextMenu | null>(null);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [maxStorage, setMaxStorage] = useState(0);
  const [storageUsed, setStorageUsed] = useState('0 B');
  const [storageLimit, setStorageLimit] = useState('0 B');
  const [storagePercentage, setStoragePercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileItem | null>(null);
  const [downloadProgress, setDownloadProgress] = useState<DownloadProgress | null>(null);

  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: 'upload_timestamp',
    direction: 'desc'
  });

  // Charger les préférences de tri au démarrage
  useEffect(() => {
    const loadSortPreferences = async () => {
      try {
        const preferences = await getPreferences();
        if (preferences.sortConfig) {
          setSortConfig(preferences.sortConfig);
        }
      } catch (error) {
        console.error('Failed to load sort preferences:', error);
      }
    };
    loadSortPreferences();
  }, []);

  // Fonction pour changer le tri et sauvegarder la préférence
  const handleSort = async (field: SortField) => {
    const newSortConfig: SortConfig = {
      field,
      direction: sortConfig.field === field && sortConfig.direction === 'asc' ? 'desc' : 'asc'
    };
    
    setSortConfig(newSortConfig);
    
    try {
      await updatePreferences({
        sortConfig: newSortConfig
      });
    } catch (error) {
      console.error('Failed to save sort preferences:', error);
    }
  };

  // Traductions
  const translations = useMemo(() => ({
    en: {
      renameFiles: "Rename file",
      rename: "Rename",
      download: "Download",
      delete: "Delete",
      remove: "Remove",
      share: "Share",
      noFiles: "No files uploaded yet",
      getStarted: "Get started by uploading your first file",
      uploadFile: "Upload a file",
      importFile: "Import from link",
      logout: "Logout",
      logoutConfirmTitle: "Logout Confirmation",
      logoutConfirmMessage: "Are you sure you want to logout?",
      cancel: "Cancel",
      storageUsed: "Storage Used",
      of: "of",
      loading: "Loading...",
      shareFile: "Share file",
      shareLink: "Share link",
      copied: "Copied",
      copiedd: "Copy",
      downloading: "Downloading",
      shareLinkMessage: "Share this link with the people you want to share the file with",
      sortByName: "Sort by name",
      sortBySize: "Sort by size",
      sortByDate: "Sort by date",
    },
    fr: {
      renameFiles: "Renommer le fichier",
      rename: "Renommer",
      download: "Télécharger",
      delete: "Supprimer",
      remove: "Retirer",
      share: "Partager",
      noFiles: "Aucun fichier téléversé",
      getStarted: "Commencez en téléversant votre premier fichier",
      uploadFile: "Téléverser un fichier",
      importFile: "Importer depuis un lien",
      logout: "Déconnexion",
      logoutConfirmTitle: "Confirmation de déconnexion",
      logoutConfirmMessage: "Êtes-vous sûr de vouloir vous déconnecter ?",
      cancel: "Annuler",
      storageUsed: "Espace utilisé",
      of: "sur",
      loading: "Chargement...",
      shareFile: "Partager le fichier",
      shareLink: "Lien de partage",
      copied: "Copié",
      copiedd: "Copier",
      downloading: "Téléchargement de",
      shareLinkMessage: "Partagez ce lien avec les personnes avec qui vous souhaitez partager le fichier",
      sortByName: "Trier par nom",
      sortBySize: "Trier par taille",
      sortByDate: "Trier par date",
    }
  }), []);

  const t = translations[language as keyof typeof translations];

  const handleUploadClick = useCallback(() => {
    setIsUploadModalOpen(true);
  }, []);

  const ShareModal: React.FC<ShareModalProps> = ({ isOpen, onClose, shareLink, isDark }) => {
    const [copied, setCopied] = useState(false);
  
    const handleCopy = async () => {
      try {
        await navigator.clipboard.writeText(shareLink);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen p-4">
          {/* Overlay */}
          <div className="fixed inset-0 bg-black bg-opacity-30" onClick={onClose} />
          
          {/* Modal */}
          <div className={`relative ${isDark ? 'bg-gray-800' : 'bg-white'} rounded-lg w-full max-w-md mx-4 shadow-xl`}>
            {/* Header */}
            <div className="p-4 border-b border-gray-200 dark:border-gray-700">
              <h2 className={`text-lg font-medium ${isDark ? 'text-white' : 'text-gray-900'}`}>
                {t.shareFile}
              </h2>
              {/* Close button */}
              <button
                onClick={onClose}
                className={`absolute top-4 right-4 p-2 rounded-full ${
                  isDark ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                }`}
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
  
            {/* Content */}
            <div className="p-6">
              <div className="mb-4">
                <label className={`block text-sm font-medium mb-2 ${isDark ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t.shareLink}
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={shareLink}
                    readOnly
                    className={`flex-1 p-2 rounded-md border ${
                      isDark 
                        ? 'bg-gray-700 border-gray-600 text-white' 
                        : 'bg-white border-gray-300 text-black'
                    } focus:outline-none focus:ring-2 focus:ring-purple-500`}
                  />
                  <button
                    onClick={handleCopy}
                    className={`px-4 py-2 rounded-md transition-colors duration-200 ${
                      copied
                        ? 'bg-green-500 hover:bg-green-600'
                        : 'bg-purple-600 hover:bg-purple-700'
                    } text-white flex items-center`}
                  >
                    {copied ? (
                      <>
                        <svg
                          className="w-5 h-5 mr-1"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                        {t.copied}
                      </>
                    ) : (
                      <>
                        <svg
                          className="w-5 h-5 mr-1"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                          />
                        </svg>
                        {t.copiedd}
                      </>
                    )}
                  </button>
                </div>
                <p className={`mt-2 text-sm ${isDark ? 'text-gray-400' : 'text-gray-500'}`}>
                  {t.shareLinkMessage}
                </p>
              </div>
  
              {/* Footer */}
              <div className="mt-6 flex justify-end">
                <button
                  onClick={onClose}
                  className={`px-4 py-2 rounded-md ${
                    isDark
                      ? 'bg-gray-700 hover:bg-gray-600 text-white'
                      : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
                  } transition-colors duration-200`}
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenameModal: React.FC<RenameModalProps> = ({
    isOpen,
    onClose,
    onRename,
    currentName,
    isDark
  }) => {
    const [newName, setNewName] = useState(currentName);
  
    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      onRename(newName);
      onClose();
    };
  
    if (!isOpen) return null;
  
    
  
    return (
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="fixed inset-0 bg-black bg-opacity-25" onClick={onClose} />
          <div className={`relative ${isDark ? 'bg-gray-800' : 'bg-white'} rounded-lg p-6 shadow-xl`}>
            <h3 className={`text-lg font-medium ${isDark ? 'text-white' : 'text-gray-900'}`}>
              {t.renameFiles}
            </h3>
            <form onSubmit={handleSubmit} className="mt-4">
              <input
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                className={`w-full px-3 py-2 border rounded-md ${
                  isDark
                    ? 'bg-gray-700 border-gray-600 text-white'
                    : 'bg-white border-gray-300 text-black'
                }`}
              />
              <div className="mt-4 flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={onClose}
                  className={`px-4 py-2 rounded-md ${
                    isDark
                      ? 'bg-gray-700 text-white hover:bg-gray-600'
                      : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                  }`}
                >
                  {t.cancel}
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700"
                >
                  {t.rename}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const handleShare = async (fileId: string) => {
    try {
      const result = await createShareLink(fileId);
      setSelectedFileForShare(result.share_url);
      setShareModalOpen(true);
      await fetchFiles();
    } catch (error) {
      console.error('Error creating share link:', error);
      alert('Failed to create share link');
    }
  };

  const fetchFiles = async () => {
    try {
        const userKey = sessionStorage.getItem('userKey');
        if (!userKey) {
            navigate('/login');
            return;
        }

        const response = await check(userKey);
        if (response.valid) {
            setFiles(response.files || []);
            setStorageLimit(formatSize(response.max_storage));
            setStorageUsed(formatSize(response.current_usage));
            setStoragePercentage((response.current_usage / response.max_storage) * 100);
        }
    } catch (error) {
        console.error('Error fetching files:', error);
        setError('Failed to fetch files');
    }
};

  const fetchStorageInfo = useCallback(async () => {
    const userKey = sessionStorage.getItem('userKey');
    if (!userKey) {
      navigate('/');
      return;
    }

    try {
      const response = await check(userKey);
      
      if (response.valid) {
        setStorageUsed(formatSize(response.current_usage));
        setStorageLimit(formatSize(response.max_storage));
        setMaxStorage(response.max_storage);
        setStoragePercentage((response.current_usage / response.max_storage) * 100);
        setFiles(response.files);

        if (response.preferences) {
          if (response.preferences.language && response.preferences.language !== language) {
            setLanguage(response.preferences.language);
          }
          if (response.preferences.theme && response.preferences.theme !== theme) {
            toggleTheme();
          }
        }
      } else {
        sessionStorage.removeItem('userKey');
        navigate('/');
      }
    } catch (error) {
      console.error('Error fetching storage info:', error);
      setError('Failed to load storage info. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [navigate, setLanguage, theme, toggleTheme, language]);

  // Effets
  useEffect(() => {
    fetchStorageInfo();
  }, [fetchStorageInfo]);

  useEffect(() => {
    const handleClickOutside = () => setContextMenu(null);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  // Gestionnaires d'événements
  const handleFileUpload = async (file: File, onProgress?: (progress: number) => void) => {
    const userKey = sessionStorage.getItem('userKey');
    if (!userKey) {
      console.error('User key not found');
      alert('User key not found. Please log in again.');
      return;
    }

    try {
      const result = await uploadToDiscord(file, onProgress || (() => {}));
      console.log('File uploaded successfully:', result);
      await fetchStorageInfo();
      setIsUploadModalOpen(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('An error occurred while uploading the file. Please try again.');
    }
  };

  const handleContextMenu = (event: React.MouseEvent, fileId: string) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      fileId,
    });
  };

  // Fonction de tri
  const sortedFiles = useMemo(() => {
    return [...files].sort((a, b) => {
      switch (sortConfig.field) {
        case 'name':
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          return sortConfig.direction === 'asc' 
            ? nameA.localeCompare(nameB)
            : nameB.localeCompare(nameA);
        
        case 'size':
          return sortConfig.direction === 'asc' 
            ? a.size - b.size
            : b.size - a.size;
        
        case 'upload_timestamp':
          const timeA = a.upload_timestamp || 0;
          const timeB = b.upload_timestamp || 0;
          return sortConfig.direction === 'asc'
            ? timeA - timeB
            : timeB - timeA;
            
        default:
          return 0;
      }
    });
  }, [files, sortConfig]);

  const handleRemoveImportedFile = async (fileId: string) => {
    try {
      const userKey = sessionStorage.getItem('userKey');
      if (!userKey) {
        navigate('/login');
        return;
      }
  
      const file = files.find(f => f.uuid === fileId);
      if (!file) {
        alert('File not found');
        return;
      }
  
      await deleteFile(fileId);
  
      setFiles(prevFiles => prevFiles.filter(f => f.uuid !== fileId));
      setContextMenu(null);
  
      await fetchStorageInfo();
  
      alert('Le fichier a été retiré de votre espace');
    } catch (error) {
      console.error('Error removing imported file:', error);
      alert('Une erreur est survenue lors du retrait du fichier');
    }
  };
  

  const handleImportShare = async (shareId: string) => {
    setIsLoading(true);
    try {
      await importSharedFile(shareId);
      await fetchStorageInfo();
      setIsUploadModalOpen(false);
    } catch (error) {
      console.error('Error importing shared file:', error);
      alert('Failed to import shared file');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFile = async (fileId: string) => {
    const userKey = sessionStorage.getItem('userKey');
    if (!userKey) {
      navigate('/login');
      return;
    }

    try {
      const result = await deleteFile(fileId);
      alert(`File "${result.file_name}" has been deleted.`);
      await fetchStorageInfo();
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('Failed to delete the file. Please try again.');
    }
    setContextMenu(null);
  };

  const handleRename = async (newName: string) => {
    if (selectedFile) {
      try {
        await renameFile(selectedFile.uuid, newName);
        await fetchStorageInfo();
      } catch (error) {
        console.error('Error renaming file:', error);
        alert('Failed to rename file. Please try again.');
      }
    }
  };

  const handleDownload = async (fileId: string) => {
    try {
      const userKey = sessionStorage.getItem('userKey');
      if (!userKey) {
        navigate('/login');
        return;
      }

      const file = files.find(f => f.uuid === fileId);
      if (!file) {
        throw new Error('File not found');
      }

      setDownloadProgress({ fileId, progress: 0, fileName: file.name });
      
      const blob = await downloadFile(file.uuid, (progress) => {
        setDownloadProgress(prev => prev ? { ...prev, progress } : null);
      });
      
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Failed to download the file. Please try again.');
    } finally {
      // Attendez un peu avant de faire disparaître la barre de progression
      setTimeout(() => {
        setDownloadProgress(null);
      }, 1000);
    }
    setContextMenu(null);
  };

  const handleLogout = () => {
    setIsLogoutModalOpen(true);
  };

  const confirmLogout = () => {
    sessionStorage.removeItem('userKey');
    navigate('/');
  };

  const load_user_data = (user_key: string) => {
    try {
      const storedData = localStorage.getItem(`user_data_${user_key}`);
      if (storedData) {
        return JSON.parse(storedData);
      }
      return null;
    } catch (error) {
      console.error('Error loading user data:', error);
      return null;
    }
  };
  
  const save_user_data = (user_key: string, data: any) => {
    try {
      localStorage.setItem(`user_data_${user_key}`, JSON.stringify(data));
      return true;
    } catch (error) {
      console.error('Error saving user data:', error);
      return false;
    }
  };
  
  const renderFileGrid = () => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {sortedFiles.map((file) => (
          <div
            key={file.uuid}
            onContextMenu={(e) => handleContextMenu(e, file.uuid)}
            className="relative"
          >
            <FileGridItem
              file={file}
              isDark={isDark}
              onShare={handleShare}
            />
          </div>
        ))}
      </div>
    );
  };

  // Rendu
  return (
    <div className={`flex flex-col h-screen ${isDark ? 'color-night bg-gray-900 text-white' : 'color-day bg-gray-100 text-black'}`}>
      <Navbar onUploadClick={handleUploadClick} />
      
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className={`flex-1 overflow-x-hidden overflow-y-auto ${isDark ? 'bg-gray-800' : 'bg-gray-50'}`}>
          <div className="container mx-auto px-4 py-8 h-full">
            {isLoading ? (
              <p>{t.loading}</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : files.length === 0 ? (
              <EmptyState onUploadClick={() => setIsUploadModalOpen(true)} isDark={isDark} t={t} />
            ) : (
              <>
                <SortButtons
                  onSort={handleSort}
                  currentSort={sortConfig}
                  isDark={isDark}
                  t={t}
                />
                {renderFileGrid()}
              </>
            )}
          </div>
        </main>

        {/* Barre de stockage */}
        <div className="w-full py-4 px-6 flex items-center justify-center">
          <div className="w-full max-w-xl">
            <div className="flex justify-between mb-1">
              <span className="text-base font-medium text-purple-700 dark:text-purple-400">
                {t.storageUsed}
              </span>
              <span className="text-sm font-medium text-purple-700 dark:text-purple-400">
                {storageUsed} {t.of} {storageLimit}
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-purple-600 h-2.5 rounded-full"
                style={{ width: `${storagePercentage}%` }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Bouton de déconnexion */}
      <button
        onClick={handleLogout}
        className="fixed bottom-4 right-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full flex items-center justify-center"
      >
        <LogOut className="mr-2" size={20} />
        <span className='hidden md:inline'>{t.logout}</span> 
      </button>

      {/* Share Modal */}
      <ShareModal
        isOpen={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        shareLink={selectedFileForShare || ''}
        isDark={isDark}
      />

      {/* Upload/Import Modal */}
      <FileImportModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onFileUpload={handleFileUpload}
        isDark={isDark}
        t={t}
        onRefresh={fetchStorageInfo} 
      />

      {/* Modal de déconnexion */}
      <Transition show={isLogoutModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => setIsLogoutModalOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-30" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className={`relative w-full max-w-md p-6 mx-auto ${isDark ? 'bg-gray-800' : 'bg-white'} rounded-xl shadow-xl`}>
                <Dialog.Title className={`text-lg font-medium ${isDark ? 'text-white' : 'text-gray-900'}`}>
                  {t.logoutConfirmTitle}
                </Dialog.Title>
                <div className="mt-4">
                  <p className={`text-sm ${isDark ? 'text-gray-300' : 'text-gray-500'}`}>
                    {t.logoutConfirmMessage}
                  </p>
                </div>
                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    className={`px-4 py-2 text-sm font-medium rounded-md ${
                      isDark
                        ? 'bg-gray-700 hover:bg-gray-600 text-white'
                        : 'bg-gray-200 hover:bg-gray-300 text-gray-900'
                    }`}
                    onClick={() => setIsLogoutModalOpen(false)}
                  >
                    {t.cancel}
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white bg-red-500 hover:bg-red-600 rounded-md"
                    onClick={confirmLogout}
                  >
                    {t.logout}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Menu contextuel */}
      {contextMenu && (
        <div
          style={{
            position: 'fixed',
            top: contextMenu.y,
            left: contextMenu.x,
            zIndex: 50,
          }}
          className={`${isDark ? 'bg-gray-700' : 'bg-white'} rounded-md shadow-lg`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="py-1">
            {/* Bouton de téléchargement - toujours visible */}
            <button
              className={`block w-full text-left px-4 py-2 text-sm ${
                isDark ? 'text-gray-200 hover:bg-gray-600' : 'text-gray-900 hover:bg-gray-100'
              }`}
              onClick={() => handleDownload(contextMenu.fileId)}
            >
              <Download className="inline-block mr-2 h-5 w-5" aria-hidden="true" />
              {t.download}
            </button>
            
            {/* Bouton de partage - uniquement pour les fichiers non importés et non partagés */}
            {canShareFile(files.find(f => f.uuid === contextMenu.fileId) as FileItem, sessionStorage.getItem('userKey')) && (
              <button
                className={`block w-full text-left px-4 py-2 text-sm ${
                  isDark ? 'text-gray-200 hover:bg-gray-600' : 'text-gray-900 hover:bg-gray-100'
                }`}
                onClick={() => handleShare(contextMenu.fileId)}
              >
                <Share2 className="inline-block mr-2 h-5 w-5" aria-hidden="true" />
                {t.share}
              </button>
            )}

            {/* Actions selon le type de fichier */}
            {files.find(f => f.uuid === contextMenu.fileId)?.is_imported ? (
              <button
                className={`block w-full text-left px-4 py-2 text-sm ${
                  isDark ? 'text-gray-200 hover:bg-gray-600' : 'text-gray-900 hover:bg-gray-100'
                }`}
                onClick={() => handleRemoveImportedFile(contextMenu.fileId)}
              >
                <Trash className="inline-block mr-2 h-5 w-5" aria-hidden="true" />
                {t.remove}
              </button>
            ) : (
              <>
                <button
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    isDark ? 'text-gray-200 hover:bg-gray-600' : 'text-gray-900 hover:bg-gray-100'
                  }`}
                  onClick={() => {
                    const file = files.find(f => f.uuid === contextMenu.fileId);
                    if (file) {
                      setSelectedFile(file);
                      setIsRenameModalOpen(true);
                      setContextMenu(null);
                    }
                  }}
                >
                  <Edit2 className="inline-block mr-2 h-5 w-5" aria-hidden="true" />
                  {t.rename}
                </button>
                
                <button
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    isDark ? 'text-gray-200 hover:bg-gray-600' : 'text-gray-900 hover:bg-gray-100'
                  }`}
                  onClick={() => handleDeleteFile(contextMenu.fileId)}
                >
                  <Trash className="inline-block mr-2 h-5 w-5" aria-hidden="true" />
                  {t.delete}
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {/* Modal de renommage */}
      <RenameModal
        isOpen={isRenameModalOpen}
        onClose={() => setIsRenameModalOpen(false)}
        onRename={handleRename}
        currentName={selectedFile?.name || ''}
        isDark={isDark}
      />

      {/* Barre de progression du téléchargement */}
      {downloadProgress && (
        <div className="fixed bottom-4 left-4 w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 z-50">
          <div className="text-sm mb-2 truncate">
            {t.downloading} {downloadProgress.fileName}
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-purple-600 h-2.5 rounded-full transition-all duration-300"
              style={{ width: `${downloadProgress.progress}%` }}
            />
          </div>
          <div className="text-xs mt-1 text-right">
            {downloadProgress.progress.toFixed(0)}%
          </div>
        </div>
      )}
    </div>
  );
};

const SortButtons: React.FC<{
  onSort: (field: SortField) => void;
  currentSort: SortConfig;
  isDark: boolean;
  t: any;
}> = ({ onSort, currentSort, isDark, t }) => (
  <div className="mb-4 flex flex-wrap gap-2">
    <button
      onClick={() => onSort('name')}
      className={`px-4 py-2 rounded-md flex items-center gap-2 ${
        isDark 
          ? 'bg-gray-700 hover:bg-gray-600 text-white' 
          : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
      }`}
    >
      {t.sortByName}
      {currentSort.field === 'name' && (
        <span>{currentSort.direction === 'asc' ? '↑' : '↓'}</span>
      )}
    </button>
    
    <button
      onClick={() => onSort('size')}
      className={`px-4 py-2 rounded-md flex items-center gap-2 ${
        isDark 
          ? 'bg-gray-700 hover:bg-gray-600 text-white' 
          : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
      }`}
    >
      {t.sortBySize}
      {currentSort.field === 'size' && (
        <span>{currentSort.direction === 'asc' ? '↑' : '↓'}</span>
      )}
    </button>
    
    <button
      onClick={() => onSort('upload_timestamp')}
      className={`px-4 py-2 rounded-md flex items-center gap-2 ${
        isDark 
          ? 'bg-gray-700 hover:bg-gray-600 text-white' 
          : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
      }`}
    >
      {t.sortByDate}
      {currentSort.field === 'upload_timestamp' && (
        <span>{currentSort.direction === 'asc' ? '↑' : '↓'}</span>
      )}
    </button>
  </div>
);

export default LunaSpaceDrive;
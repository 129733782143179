import React, { useMemo } from 'react';
import { useTheme } from '../ThemeContext';
import Navbar from './Navbar';
import { useLanguage } from '../LanguageContext';

const AboutUs: React.FC = () => {
  const { theme } = useTheme();
  const isDark = theme === 'dark';
  const { language } = useLanguage();

  const translations = useMemo(() => ({
    en: {
      title: "About LunaSpace",
      text1: "Welcome to LunaSpace, your secure and innovative file storage solution. At LunaSpace, we understand the importance of confidentiality and security for your data. That's why we've developed a storage platform that uses AES encryption to ensure your files remain protected at all times.",
      text2: "Unlike traditional solutions, LunaSpace does not require an account system. You don't need to create user accounts or manage complex passwords. Instead, we've opted for a simplified, secure approach: access to your files is via a unique key. This key is your passport to your data, ensuring a secure, hassle-free connection.",
      text3: "With LunaSpace, you benefit from a smooth, intuitive user experience. Store, access and share your files with confidence, safe in the knowledge that they're protected by state-of-the-art encryption technology. Whether you're an individual or a business, LunaSpace is designed to meet your storage needs efficiently and securely.",
      text4: "Join us and discover the peace of mind that comes with encrypted file storage with LunaSpace. Your data is precious, and we're committed to protecting it with the highest level of security.",
      title1: "First",
      title2: "Second",
      title3: "Third"
    },
    fr: {
      title: "À propos LunaSpace",
      text1: "Bienvenue chez LunaSpace, votre solution de stockage de fichiers sécurisée et innovante. Chez LunaSpace, nous comprenons l'importance de la confidentialité et de la sécurité de vos données. C'est pourquoi nous avons développé une plateforme de stockage qui utilise le chiffrement AES pour garantir que vos fichiers restent protégés en tout temps.",
      text2: "Contrairement aux solutions traditionnelles, LunaSpace ne nécessite pas de système de compte. Vous n'avez pas besoin de créer de compte utilisateur ou de gérer des mots de passe complexes. Au lieu de cela, nous avons opté pour une approche simplifiée et sécurisée : l'accès à vos fichiers se fait via une clé unique. Cette clé est votre passeport vers vos données, assurant une connexion sécurisée et sans tracas.",
      text3: "Avec LunaSpace, vous bénéficiez d'une expérience utilisateur fluide et intuitive. Stockez, accédez et partagez vos fichiers en toute confiance, sachant qu'ils sont protégés par une technologie de chiffrement de pointe. Que vous soyez un particulier ou une entreprise, LunaSpace est conçu pour répondre à vos besoins de stockage de manière efficace et sécurisée.",
      text4: "Rejoignez-nous et découvrez la tranquillité d'esprit que procure le stockage de fichiers chiffrés avec LunaSpace. Vos données sont précieuses, et nous nous engageons à les protéger avec le plus haut niveau de sécurité.",
      title1: "Premier",
      title2: "Deuxième",
      title3: "Troisième"
    }
  }), []);

  const t = translations[language as keyof typeof translations];

  return (
    <div className={`min-h-screen flex flex-col ${isDark ? 'color-night bg-gray-900 text-white' : 'color-day bg-gray-100 text-gray-900'}`}>
        <Navbar />
      
      {/* Main content */}
      <div className="flex-grow container mx-auto px-4 py-8">
        <h1 className={`text-3xl font-bold mb-6 ${isDark ? 'text-purple-400' : 'text-purple-600'}`}>{t.title}</h1>
        <div className={`bg-opacity-80 ${isDark ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg p-6`}>
          <p className="mb-4">
          {t.text1}
          </p>
          <h2 className={`text-2xl font-semibold mb-3 ${isDark ? 'text-purple-300' : 'text-purple-500'}`}>{t.title1}</h2>
          <p>
          {t.text2}
          </p>
          <h2 className={`text-2xl font-semibold mb-3 ${isDark ? 'text-purple-300' : 'text-purple-500'}`}>{t.title2}</h2>
          <p>
          {t.text3}
          </p>
          <h2 className={`text-2xl font-semibold mb-3 ${isDark ? 'text-purple-300' : 'text-purple-500'}`}>{t.title3}</h2>
          <p>
          {t.text4}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes gradientAnimation {
  0% {
    stop-color: #441fe9;
  }
  25% {
    stop-color: #6a1eff;
  }
  50% {
    stop-color: #9a1eff;  
  }
  75% {
    stop-color: #6a1eff;
  }
  100% {
    stop-color: #441fe9;
  }
}

#logoGradient stop {
  animation: gradientAnimation 5s ease infinite;
}`, "",{"version":3,"sources":["webpack://./src/components/LunaSpaceLogo.css"],"names":[],"mappings":"AAAA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,6CAA6C;AAC/C","sourcesContent":["@keyframes gradientAnimation {\n  0% {\n    stop-color: #441fe9;\n  }\n  25% {\n    stop-color: #6a1eff;\n  }\n  50% {\n    stop-color: #9a1eff;  \n  }\n  75% {\n    stop-color: #6a1eff;\n  }\n  100% {\n    stop-color: #441fe9;\n  }\n}\n\n#logoGradient stop {\n  animation: gradientAnimation 5s ease infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

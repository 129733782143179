interface PlanTranslation {
    en: string;
    fr: string;
}

interface PlanDefinitions {
    [key: string]: PlanTranslation;
}

export const planTranslations: PlanDefinitions = {
    'AMB': {
        en: 'Ambassador',
        fr: 'Ambassadeur'
    },
    'PRO': {
        en: 'Professional',
        fr: 'Professionnel'
    },
    'STD': {
        en: 'Standard',
        fr: 'Standard'
    },
    'FREE': {
        en: 'Free',
        fr: 'Gratuit'
    },
    'DEF': {
        en: 'Default',
        fr: 'Défaut'
    }
};

export const translatePlan = (plan: string, language: 'en' | 'fr'): string => {
    const upperPlan = plan.toUpperCase();
    
    if (planTranslations[upperPlan]) {
        return planTranslations[upperPlan][language];
    }
    
    return plan;
}; 
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Upload, Settings } from 'lucide-react';
import { useTheme } from '../ThemeContext';
import { useLanguage } from '../LanguageContext';
import LunaSpaceLogo from './LunaSpaceLogo';
import './customcss.css';

interface NavbarProps {
  onUploadClick?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onUploadClick }) => {
  const { theme } = useTheme();
  const { language } = useLanguage();
  const isDark = theme === 'dark';
  const location = useLocation();
  const navigate = useNavigate();

  const isLoggedIn = !!sessionStorage.getItem('userKey');

  const handleSettingsClick = () => {
    if (isLoggedIn) {
      navigate('/settings');
    }
  };

  const translations = {
    en: {
      home: "Home",
      about: "About Us",
      contact: "Contact",
      upload: "Upload",
      settings: "Settings"
    },
    fr: {
      home: "Accueil",
      about: "À propos",
      contact: "Contact",
      upload: "Importer",
      settings: "Paramètres"
    }
  };

  const t = translations[language as keyof typeof translations];

  return (
    <nav className={`${isDark ? 'color-day bg-gray-800 text-white' : 'color-night bg-white text-gray-800'} shadow-lg`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to={isLoggedIn ? "/me" : "/"} className="flex-shrink-0">
              <LunaSpaceLogo />
            </Link>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <Link
                  to={isLoggedIn ? "/me" : "/"}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    location.pathname === '/' || location.pathname === '/me'
                      ? 'bg-purple-600 text-white'
                      : 'hover:bg-purple-600 hover:text-white'
                  }`}
                >
                  {t.home}
                </Link>
                <Link
                  to="/about"
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    location.pathname === '/about'
                      ? 'bg-purple-600 text-white'
                      : 'hover:bg-purple-600 hover:text-white'
                  }`}
                >
                  {t.about}
                </Link>
                <Link
                  to="/contact"
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    location.pathname === '/contact'
                      ? 'bg-purple-600 text-white'
                      : 'hover:bg-purple-600 hover:text-white'
                  }`}
                >
                  {t.contact}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            {onUploadClick && (
              <button
                onClick={onUploadClick}
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  isDark ? 'bg-purple-600 hover:bg-purple-700' : 'bg-purple-500 hover:bg-purple-600'
                } text-white`}
              >
                <Upload className="inline-block mr-2" size={16} />
                {t.upload}
              </button>
            )}
            {isLoggedIn && (
            <div className="group"> 
            <button
              onClick={handleSettingsClick}
              title={t.settings}
              className={`p-2 rounded-md transition-colors ${
                location.pathname === '/settings'
                  ? 'bg-purple-600 text-white'
                  : isDark 
                    ? 'text-white hover:bg-gray-700' 
                    : 'text-gray-800 hover:bg-gray-200'
              }`}
            >
              <Settings 
                className="transition-transform duration-200 group-hover:rotate180" 
                size={20} 
              />
            </button>
          </div>
          )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

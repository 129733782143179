import React, { useState, useMemo, Fragment } from 'react';
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import { useTheme } from '../ThemeContext';
import Navbar from './Navbar';
import './customcss.css';
import { Dialog, Transition } from '@headlessui/react';
import { useLanguage } from '../LanguageContext';
import { useNavigate } from 'react-router-dom';
import AnimatedWaveBackground from './AnimatedWaveBackground';
import { check, CheckKeyResponse } from './Api';

const LoginPage: React.FC = () => {
  const [uuidKey, setUuidKey] = useState('');
  const [showUuid, setShowUuid] = useState(false);
  const { theme } = useTheme();
  const isDark = theme === 'dark';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const { language } = useLanguage();
  const navigate = useNavigate();

  const translations = useMemo(() => ({
    en: {
      title: "Sign in to your drive",
      uuidLabel: "Account key",
      signInButton: "Sign in",
      invalidUUID: "Invalid Key",
      invalidUUIDMessage: "The key you entered is invalid. Please check and try again.",
      loginSuccess: "Login Successful",
      loginSuccessMessage: "You have successfully logged in.",
      okThanks: "Ok, thanks!",
      serverError: "Server Error",
      serverErrorMessage: "An error occurred while trying to connect. Please try again later."
    },
    fr: {
      title: "Connectez-vous à votre drive",
      uuidLabel: "Clé de compte",
      signInButton: "Se connecter",
      invalidUUID: "Clé invalide",
      invalidUUIDMessage: "La clé que vous avez saisie est invalide. Veuillez vérifier et réessayer.",
      loginSuccess: "Connexion réussie",
      loginSuccessMessage: "Vous vous êtes connecté avec succès.",
      okThanks: "D'accord, merci !",
      serverError: "Erreur serveur",
      serverErrorMessage: "Une erreur s'est produite lors de la tentative de connexion. Veuillez réessayer plus tard."
    }
  }), []);

  const t = translations[language as keyof typeof translations];

  const uuidv4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!uuidv4Regex.test(uuidKey)) {
      setModalTitle(t.invalidUUID);
      setModalMessage(t.invalidUUIDMessage);
      setIsModalOpen(true);
      return;
    }

    try {
      const response: CheckKeyResponse = await check(uuidKey);
  
      if (response.valid) {
        console.log('Login successful, setting modal');
        sessionStorage.setItem('userKey', uuidKey);
        setModalTitle(t.loginSuccess);
        setModalMessage(t.loginSuccessMessage);
        setIsModalOpen(true);
      } else {
        setModalTitle(t.invalidUUID);
        setModalMessage(t.invalidUUIDMessage);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error('Login error:', error);
      setModalTitle(t.serverError);
      setModalMessage(t.serverErrorMessage);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    console.log('Closing modal, modal title:', modalTitle);
    setIsModalOpen(false);
    if (modalTitle === t.loginSuccess) {
      console.log('Navigating to /me');
      navigate('/me');
    }
  };


  return (
    <div className={`min-h-screen flex flex-col ${isDark ? 'color-night bg-gray-900 text-white' : 'color-day bg-gray-100 text-gray-900'}`}>
      <Navbar />
      
      {/* Main content */}
      <div className="flex-grow flex items-center justify-center relative overflow-hidden">
        {/* Wave background */}
        <AnimatedWaveBackground isDark={isDark} />
        <div className="z-10 w-full max-w-md">
          <h2 className={`mb-6 text-center text-3xl font-extrabold ${isDark ? 'text-white' : 'text-gray-900'}`}>{t.title}</h2>
          <div className={`${isDark ? 'bg-gray-800' : 'bg-white login-case-day'} py-8 login-case px-4 shadow sm:rounded-lg sm:px-10`}>
            <form className="space-y-6" onSubmit={handleSubmit}>
              {/* UUID Key input */}
              <div>
                <label htmlFor="uuidKey" className={`block text-sm font-medium ${isDark ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t.uuidLabel}
                </label>
                <div className="mt-1 relative">
                  <input
                    id="uuidKey"
                    name="uuidKey"
                    type={showUuid ? "text" : "password"}
                    required
                    className={`appearance-none block w-full px-3 py-2 border ${isDark ? 'border-gray-600 bg-gray-700 text-white' : 'border-gray-300 bg-white text-gray-900'} rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm`}
                    value={uuidKey}
                    onChange={(e) => setUuidKey(e.target.value)}
                    placeholder="xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    onClick={() => setShowUuid(!showUuid)}
                  >
                    {showUuid ? (
                      <EyeOffIcon className={`h-5 w-5 ${isDark ? 'text-gray-400' : 'text-gray-500'}`} />
                    ) : (
                      <EyeIcon className={`h-5 w-5 ${isDark ? 'text-gray-400' : 'text-gray-500'}`} />
                    )}
                  </button>
                </div>
              </div>

              {/* Submit button */}
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  {t.signInButton}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Notification Modal */}
      <Transition show={isModalOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-md transform overflow-hidden rounded-2xl ${isDark ? 'bg-gray-800' : 'bg-white'} p-6 text-left align-middle shadow-xl transition-all`}>
                  <Dialog.Title
                    as="h3"
                    className={`text-lg font-medium leading-6 ${isDark ? 'text-white' : 'text-gray-900'}`}
                  >
                    {modalTitle}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className={`text-sm ${isDark ? 'text-gray-300' : 'text-gray-500'}`}>
                      {modalMessage}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className={`inline-flex justify-center rounded-md border border-transparent ${
                        isDark ? 'bg-purple-600 text-white hover:bg-purple-700' : 'bg-purple-100 text-purple-900 hover:bg-purple-200'
                      } px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2`}
                      onClick={closeModal}
                    >
                      {t.okThanks}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default LoginPage;

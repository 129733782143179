import axios, { 
    AxiosResponse, 
    AxiosError, 
    AxiosInstance, 
    InternalAxiosRequestConfig,
    AxiosRequestConfig 
} from 'axios';

declare module 'axios' {
    export interface InternalAxiosRequestConfig extends AxiosRequestConfig {
        retryCount: number;
    }
}

// Constants
const API_URL = process.env.NODE_ENV === 'production' 
    ? 'https://luna-space.fr/api' 
    : 'https://luna-space.fr/api';
const API_KEY = "testtestr";
const DEFAULT_TIMEOUT = 300000;
const UPLOAD_TIMEOUT = 300000;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

// Types
interface APIErrorResponse {
    error?: string;
    message?: string;
    status?: number;
}

export interface ShareLinkResponse {
  share_url: string;
  share_id: string;
}

export interface RenameResponse {
    message: string;
    old_name: string;
    new_name: string;
    file_uuid: string;
}

export interface CheckKeyResponse {
    valid: boolean;
    max_storage: number;
    current_usage: number;
    plan: string;
    files: Array<FileMetadata>;
    preferences: UserPreferences;
    redirect?: string;
}

export interface UserPreferences {
    theme: 'light' | 'dark';
    language: 'en' | 'fr';
    sortConfig?: {
        field: 'name' | 'size' | 'upload_timestamp';
        direction: 'asc' | 'desc';
    };
}

export interface FileMetadata {
    uuid: string;
    name: string;
    size: number;
    upload_timestamp: number;
}

interface FileItem {
    uuid: string;
    name: string;
    size: number;
    upload_timestamp?: number;
    is_shared?: boolean;
    is_imported?: boolean;
    original_key?: string;
  }

export interface UploadResponse {
    message: string;
    file_uuid: string;
    total_chunks: number;
}

export interface DeleteResponse {
    message: string;
    file_name: string;
    file_size: string;
}

export interface FileInfo {
    filename: string;
    size: number;
    chunks: string[];
    uuid: string;
    extension: string | undefined;
}

export class APIError extends Error {
    constructor(
        message: string,
        public status?: number,
        public code?: string
    ) {
        super(message);
        this.name = 'APIError';
    }
}



const createAPIClient = (): AxiosInstance => {
    const client = axios.create({
        baseURL: API_URL,
        timeout: DEFAULT_TIMEOUT,
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': API_KEY
        },
        withCredentials: true
    });

    client.interceptors.response.use(
        (response) => response,
        async (error: AxiosError<APIErrorResponse>) => {
            if (!error.config) {
                return Promise.reject(new APIError('Configuration error occurred'));
            }

            const config = error.config as InternalAxiosRequestConfig;
            
            if (typeof config.retryCount === 'undefined') {
                config.retryCount = 0;
            }
            
            if (
                config &&
                error.response?.status &&
                [408, 429, 500, 502, 503, 504].includes(error.response.status) &&
                config.retryCount < MAX_RETRIES
            ) {
                config.retryCount += 1;
                const delay = RETRY_DELAY * config.retryCount;
                await new Promise(resolve => setTimeout(resolve, delay));
                return client(config);
            }

            let errorMessage: string;
            
            if (error.response?.data) {
                const responseData = error.response.data as APIErrorResponse;
                errorMessage = responseData.error || responseData.message || error.message || 'An unexpected error occurred';
            } else {
                errorMessage = error.message || 'An unexpected error occurred';
            }

            const apiError = new APIError(
                errorMessage,
                error.response?.status,
                error.code || undefined
            );

            return Promise.reject(apiError);
        }
    );

    return client;
};

// API client instance
const api = createAPIClient();

// Utility functions
export const formatSize = (bytes: number): string => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

// API functions
export const createShareLink = async (fileId: string): Promise<ShareLinkResponse> => {
    try {
      const userKey = sessionStorage.getItem('userKey');
      if (!userKey) {
        throw new APIError('User key not found');
      }
  
      const response = await api.post<ShareLinkResponse>(
        '/create_share',
        { file_uuid: fileId },
        {
          headers: {
            'X-User-Key': userKey
          }
        }
      );
      
      // Construire l'URL complète de partage
      const shareUrl = `https://luna-space.fr/share/${response.data.share_id}`;
      return {
        share_url: shareUrl,
        share_id: response.data.share_id
      };
    } catch (error) {
      if (error instanceof APIError) throw error;
      throw new APIError('Failed to create share link');
    }
  };
  

export const removeImportedFile = async (fileUuid: string): Promise<void> => {
    try {
      const userKey = sessionStorage.getItem('userKey');
      if (!userKey) {
        throw new Error('User key not found');
      }
  
      const response = await api.post('/remove_imported', {
        file_uuid: fileUuid
      }, {
        headers: {
          'X-User-Key': userKey
        }
      });
  
      return response.data;
    } catch (error) {
      if (error instanceof APIError) throw error;
      throw new APIError('Failed to remove imported file');
    }
};

export const importSharedFile = async (shareId: string): Promise<void> => {
  try {
    const userKey = sessionStorage.getItem('userKey');
    if (!userKey) {
      throw new APIError('User key not found');
    }

    await api.post(
      '/import_share',
      { share_id: shareId },
      {
        headers: {
          'X-User-Key': userKey
        }
      }
    );
  } catch (error) {
    if (error instanceof APIError) throw error;
    throw new APIError('Failed to import shared file');
  }
};

export const canDownloadFile = (file: FileItem, currentUserKey: string | null): boolean => {
    if (!currentUserKey) return false;
    return true;
};


export const canShareFile = (file: FileItem | undefined | null, currentUserKey: string | null): boolean => {
    if (!file || !currentUserKey) return false;
    
    if (file.is_imported) return false;
    
    if (file.is_shared) {
        return currentUserKey === sessionStorage.getItem('userKey');
    }
    
    return true;
};


export const getPreferences = async (): Promise<UserPreferences> => {
    try {
        const userKey = sessionStorage.getItem('userKey');
        if (!userKey) {
            throw new APIError('User key not found');
        }

        const response = await api.get<UserPreferences>('/preferences', {
            headers: {
                'X-User-Key': userKey
            }
        });
        return response.data;
    } catch (error) {
        if (error instanceof APIError) throw error;
        throw new APIError('Failed to get preferences');
    }
};

export const updatePreferences = async (preferences: Partial<UserPreferences>): Promise<UserPreferences> => {
    try {
        const userKey = sessionStorage.getItem('userKey');
        if (!userKey) {
            throw new APIError('User key not found');
        }

        const response = await api.put<UserPreferences>('/preferences', preferences, {
            headers: {
                'X-User-Key': userKey
            }
        });
        return response.data;
    } catch (error) {
        if (error instanceof APIError) throw error;
        throw new APIError('Failed to update preferences');
    }
};

export const check = async (userKey: string): Promise<CheckKeyResponse> => {
    try {
        const response = await api.post<CheckKeyResponse>('/check', { key: userKey });
        
        console.log('Raw API response:', response.data);
        
        const checkResponse: CheckKeyResponse = {
            valid: response.data.valid,
            max_storage: response.data.max_storage,
            current_usage: response.data.current_usage,
            plan: response.data.plan || 'Default',
            files: response.data.files || [],
            preferences: response.data.preferences
        };
        
        console.log('Processed response:', checkResponse);
        return checkResponse;
    } catch (error) {
        console.error('Check error:', error);
        if (error instanceof APIError) throw error;
        throw new APIError('Failed to check key');
    }
};

export const uploadToDiscord = async (
  file: File, 
  onProgress?: (progress: number) => void
): Promise<UploadResponse> => {
    try {
        const userKey = sessionStorage.getItem('userKey');
        if (!userKey) {
            throw new APIError('User key not found');
        }

        const formData = new FormData();
        formData.append('file', file);
        
        const response = await api.post<UploadResponse>('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-User-Key': userKey
            },
            onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress?.(percentCompleted);
                }
            }
        });

        return response.data;
    } catch (error) {
        if (error instanceof APIError) throw error;
        throw new APIError('Failed to upload file');
    }
};

export const saveFileInfo = async (fileInfo: FileInfo): Promise<UploadResponse> => {
    try {
        const response = await api.post<UploadResponse>('/save_file_info', fileInfo);
        return response.data;
    } catch (error) {
        if (error instanceof APIError) throw error;
        throw new APIError('Failed to save file information');
    }
};

export const renameFile = async (fileUuid: string, newName: string): Promise<RenameResponse> => {
    try {
        const userKey = sessionStorage.getItem('userKey');
        if (!userKey) {
            throw new APIError('User key not found');
        }

        const response = await api.post<RenameResponse>(
            '/rename_file',
            { file_uuid: fileUuid, new_name: newName },
            {
                headers: {
                    'X-User-Key': userKey
                }
            }
        );

        return response.data;
    } catch (error) {
        if (error instanceof APIError) throw error;
        throw new APIError('Failed to rename file');
    }
};

export const deleteFile = async (fileUuid: string): Promise<DeleteResponse> => {
    try {
        const userKey = sessionStorage.getItem('userKey');
        if (!userKey) {
            throw new APIError('User key not found');
        }

        const response = await api.post<DeleteResponse>('/delete_file', 
            { file_uuid: fileUuid },
            {
                headers: {
                    'X-User-Key': userKey
                }
            }
        );
        return response.data;
    } catch (error) {
        if (error instanceof APIError) throw error;
        throw new APIError('Failed to delete file');
    }
};

export const downloadFile = async (
    fileUuid: string,
    onProgress?: (progress: number) => void
): Promise<Blob> => {
    try {
        const userKey = sessionStorage.getItem('userKey');
        if (!userKey) {
            throw new APIError('User key not found');
        }

        const response = await api.get<Blob>(`/download/${userKey}/${fileUuid}`, {
            responseType: 'blob',
            headers: {
                'X-User-Key': userKey
            },
            onDownloadProgress: (progressEvent) => {
                if (progressEvent.total) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress?.(percentCompleted);
                }
            }
        });
        
        if (!response.data) {
            throw new APIError('No data received from server');
        }
        
        return response.data;
    } catch (error) {
        console.error('Download error:', error);
        if (error instanceof APIError) throw error;
        if (axios.isAxiosError(error)) {
            throw new APIError(
                error.response?.data?.error || 'Failed to download file',
                error.response?.status
            );
        }
        throw new APIError('Failed to download file');
    }
};


export const logout = async (): Promise<void> => {
    try {
        await api.post('/logout');
        sessionStorage.removeItem('userKey');
    } catch (error) {
        if (error instanceof APIError) throw error;
        throw new APIError('Failed to logout');
    }
};

export const loadUserInfo = async (setUserPlan: (plan: string) => void) => {
    try {
        const userKey = sessionStorage.getItem('userKey');
        if (!userKey) {
            console.log('No user key found');
            throw new APIError('User key not found');
        }
        
        const response = await check(userKey);
        console.log('Check response for plan:', response);
        
        if (response && response.plan) {
            console.log('Setting plan to:', response.plan);
            setUserPlan(response.plan);
        } else {
            console.log('No plan found in response, using default');
            setUserPlan('Default');
        }
        
    } catch (error) {
        console.error('Failed to load user info:', error);
        setUserPlan('Default');
    }
};
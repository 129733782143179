import React, { useMemo, useEffect, useState } from 'react';
import { useTheme } from '../ThemeContext';
import { useLanguage } from '../LanguageContext';
import { useNavigate } from 'react-router-dom';
import { Sun, Moon, Lock, LogOut } from 'lucide-react';
import Navbar from './Navbar';
import { getPreferences, updatePreferences, check } from './Api';
import { translatePlan } from '../utils/planTranslations';
import frFlag from '../utils/fr.png';
import usFlag from '../utils/us.png';
import cnFlag from '../utils/cn.png';
import ruFlag from '../utils/ru.png';


const Settings: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  const { language, setLanguage } = useLanguage();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const navigate = useNavigate();
  const isDark = theme === 'dark';
  const [userPlan, setUserPlan] = useState<string | null>(null);
  const [times, setTimes] = useState({
    washington: '',
    moscow: '',
    beijing: '',
    paris: ''
  });

  useEffect(() => {
    const userKey = sessionStorage.getItem('userKey');
    if (!userKey) {
      navigate('/', { replace: true });
      return;
    }

    
    const loadPreferences = async () => {
      try {
        const prefs = await getPreferences();
        setLanguage(prefs.language);
        if (theme !== prefs.theme) {
          toggleTheme();
        }
      } catch (error) {
        console.error('Failed to load preferences:', error);
      }
    };

    const loadUserInfo = async () => {
      try {
        const response = await check(userKey);
        console.log('User info response:', response);
        if (response.plan) {
          setUserPlan(response.plan);
          console.log('User plan set to:', response.plan);
        }
      } catch (error) {
        console.error('Failed to load user info:', error);
      }
    };

    loadPreferences();
    loadUserInfo();
  }, [navigate, setLanguage, theme, toggleTheme]);

  useEffect(() => {
    const updateTimes = () => {
      const getFormattedDateTime = (timeZone: string) => {
        const date = new Date();
        const timeFormatter = new Intl.DateTimeFormat('fr-FR', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZone: timeZone
        });

        const dateFormatter = new Intl.DateTimeFormat('fr-FR', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          timeZone: timeZone
        });

        const time = timeFormatter.format(date);
        const fullDate = dateFormatter.format(date);
        
        // Première lettre en majuscule pour le jour et le mois
        const formattedDate = fullDate.split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

        return `${time} • ${formattedDate}`;
      };
      
      setTimes({
        washington: getFormattedDateTime('America/New_York'),
        moscow: getFormattedDateTime('Europe/Moscow'),
        beijing: getFormattedDateTime('Asia/Shanghai'),
        paris: getFormattedDateTime('Europe/Paris')
      });
    };

    updateTimes();
    const interval = setInterval(updateTimes, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleThemeChange = async () => {
    try {
      const newTheme = theme === 'light' ? 'dark' : 'light';
      await updatePreferences({ theme: newTheme });
      toggleTheme();
    } catch (error) {
      console.error('Failed to update theme:', error);
    }
  };

  const handleLanguageChange = async (newLanguage: 'en' | 'fr') => {
    try {
      await updatePreferences({ language: newLanguage });
      setLanguage(newLanguage);
    } catch (error) {
      console.error('Failed to update language:', error);
    }
  };

  const translations = useMemo(() => ({
    en: {
      title: "Settings",
      appearance: "Appearance",
      language: "Language",
      english: "English",
      french: "French",
      saveChanges: "Save Changes",
      resetSettings: "Reset Settings",
      encryptionKey: "Encryption Key",
      enterEncryptionKey: "Enter your encryption key",
      logout: "Logout",
      worldClock: "World Clock",
      washington: "Washington DC",
      moscow: "Moscow",
      beijing: "Beijing",
      paris: "Paris"
    },
    fr: {
      title: "Paramètres",
      appearance: "Apparence",
      language: "Langue",
      english: "Anglais",
      french: "Français",
      saveChanges: "Enregistrer",
      resetSettings: "Réinitialiser",
      encryptionKey: "Clé de chiffrement",
      enterEncryptionKey: "Entrez votre clé de chiffrement",
      logout: "Déconnexion",
      worldClock: "Horloge mondiale",
      washington: "Washington DC",
      moscow: "Moscou",
      beijing: "Pékin",
      paris: "Paris"
    }
  }), []);

  const t = translations[language as keyof typeof translations];

  const handleLogout = () => {
    setIsLogoutModalOpen(true);
  };

  const displayPlan = useMemo(() => {
    return translatePlan(userPlan || 'DEF', language as 'en' | 'fr');
  }, [userPlan, language]);

  return (
    <div className={`min-h-screen flex flex-col ${isDark ? 'color-night bg-gray-900 text-white' : 'color-day bg-gray-100 text-gray-900'}`}>
      <Navbar />

      <div className="flex-grow container mx-auto px-4 py-8">
        <h1 className={`text-3xl font-bold mb-6 ${isDark ? 'text-purple-400' : 'text-purple-600'}`}>
          {t.title}
        </h1>

        <div className="max-w-2xl mx-auto">
          <div className={`bg-opacity-80 ${isDark ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg p-6 mb-6`}>
            <h2 className={`text-2xl font-semibold mb-4 ${isDark ? 'text-purple-300' : 'text-purple-500'}`}>
              {t.appearance}
            </h2>
            
            <div className="flex items-center justify-between mb-6 pb-4 border-b border-gray-200 dark:border-gray-700">
              <span className={`text-lg ${isDark ? 'text-gray-300' : 'text-gray-700'}`}>
                {language === 'fr' ? 'Plan' : 'Plan'}
              </span>
              <span className="px-3 py-1 text-sm font-semibold rounded-full bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200">
                {displayPlan}
              </span>
            </div>

            <div className="space-y-6">
              {/* Theme Switch */}
              <div className="flex items-center justify-between">
                <span className="flex items-center gap-2">
                  {isDark ? <Moon className="w-5 h-5" /> : <Sun className="w-5 h-5" />}
                  <span>{t.appearance}</span>
                </span>
                <button
                  onClick={handleThemeChange}
                  className={`p-2 rounded-full ${
                    isDark 
                      ? 'bg-gray-700 hover:bg-gray-600' 
                      : 'bg-purple-100 hover:bg-purple-200'
                  } transition-colors`}
                >
                  {isDark ? <Sun className="w-5 h-5 text-yellow-400" /> : <Moon className="w-5 h-5 text-purple-600" />}
                </button>
              </div>

              {/* Language Selector */}
              <div className="flex items-center justify-between">
                <span>{t.language}</span>
                <select
                  value={language}
                  onChange={(e) => handleLanguageChange(e.target.value as 'en' | 'fr')}
                  className={`px-4 py-2 rounded-md ${
                    isDark
                      ? 'bg-gray-700 text-white border-gray-600'
                      : 'bg-white text-gray-900 border-gray-300'
                  } border focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                >
                  <option value="en">{t.english}</option>
                  <option value="fr">{t.french}</option>
                </select>
              </div>

              {/* Encryption Key Input */}
              <div className="space-y-2">
                <label className="flex items-center gap-2">
                  <Lock className="w-5 h-5" />
                  <span>{t.encryptionKey}</span>
                </label>
                <input
                  type="password"
                  placeholder={t.enterEncryptionKey}
                  className={`w-full px-4 py-2 rounded-md ${
                    isDark
                      ? 'bg-gray-700 text-white border-gray-600'
                      : 'bg-white text-gray-900 border-gray-300'
                  } border focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                />
              </div>

              {/* World Clock */}
              <div className="mt-6 pt-4 border-t border-gray-200 dark:border-gray-700">
                <h3 className="text-lg font-semibold mb-3">{t.worldClock}</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div className={`p-3 rounded-lg ${isDark ? 'bg-gray-700' : 'bg-gray-100'}`}>
                    <div className="text-sm font-semibold mb-1">
                      <img src={usFlag} alt="US" className="w-5 h-5 inline-block mr-2" />
                      {t.washington}
                    </div>
                    <div className="text-sm">{times.washington}</div>
                  </div>
                  <div className={`p-3 rounded-lg ${isDark ? 'bg-gray-700' : 'bg-gray-100'}`}>
                    <div className="text-sm font-semibold mb-1">
                      <img src={ruFlag} alt="RU" className="w-5 h-5 inline-block mr-2" />
                      {t.moscow}
                    </div>
                    <div className="text-sm">{times.moscow}</div>
                  </div>
                  <div className={`p-3 rounded-lg ${isDark ? 'bg-gray-700' : 'bg-gray-100'}`}>
                    <div className="text-sm font-semibold mb-1">
                      <img src={cnFlag} alt="CN" className="w-5 h-5 inline-block mr-2" />
                      {t.beijing}
                    </div>
                    <div className="text-sm">{times.beijing}</div>
                  </div>
                  <div className={`p-3 rounded-lg ${isDark ? 'bg-gray-700' : 'bg-gray-100'}`}>
                    <div className="text-sm font-semibold mb-1">
                      <img src={frFlag} alt="FR" className="w-5 h-5 inline-block mr-2" />
                      {t.paris}
                    </div>
                    <div className="text-sm">{times.paris}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-4">
            <button
              className={`px-6 py-2 rounded-md ${
                isDark
                  ? 'bg-gray-700 hover:bg-gray-600'
                  : 'bg-gray-200 hover:bg-gray-300'
              } transition-colors`}
            >
              {t.resetSettings}
            </button>
            <button
              className={`px-6 py-2 rounded-md ${
                isDark
                  ? 'bg-purple-600 hover:bg-purple-700'
                  : 'bg-purple-500 hover:bg-purple-600'
              } text-white transition-colors`}
            >
              {t.saveChanges}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
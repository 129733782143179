import React, { useCallback, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload } from 'lucide-react';
import { useLanguage } from '../LanguageContext';
import { check } from './Api';

interface FileUploadProps {
  onFileUpload: (file: File, onProgress: (progress: number) => void) => void;
  isDark: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileUpload, isDark }) => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState(false);
  const { language } = useLanguage();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      
      // Vérifier la taille du fichier
      try {
        const userKey = sessionStorage.getItem('userKey');
        if (!userKey) throw new Error('User key not found');
        
        const response = await check(userKey);
        const availableSpace = response.max_storage - response.current_usage;
        
        if (selectedFile.size > availableSpace) {
          setError(language === 'fr' 
            ? 'Espace de stockage insuffisant'
            : 'Not enough storage space');
          return;
        }
        
        setFile(selectedFile);
        setError(null);
      } catch (err) {
        setError(language === 'fr'
          ? 'Erreur lors de la vérification de l\'espace disponible'
          : 'Error checking available space');
      }
    }
  }, [language]);

  const translations = useMemo(() => ({
    en: {
      upload: "Upload",
      dropFile: "Drop your file here",
      selectedFile: "Selected file",
      error: "Error",
      uploading: "Uploading..."
    },
    fr: {
      upload: "Envoyer",
      dropFile: "Glissez et déposez un fichier ici, ou cliquez pour sélectionner",
      selectedFile: "Fichier sélectionné",
      error: "Erreur",
      uploading: "Envoi en cours..."
    }
  }), []);

  const t = translations[language as keyof typeof translations];

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = async () => {
    if (file) {
      setIsUploading(true);
      setUploadProgress(0);
      try {
        await onFileUpload(file, (progress) => {
          setUploadProgress(progress);
        });
        setFile(null);
        setUploadProgress(0);
      } catch (error) {
        setError(language === 'fr' 
          ? 'Erreur lors du téléversement'
          : 'Upload error');
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <div className="p-6">
      <div
        {...getRootProps()}
        className={`p-8 border-2 border-dashed rounded-lg text-center cursor-pointer transition-colors
          ${isDark
            ? 'bg-gray-800 border-gray-600 hover:border-purple-500'
            : 'bg-gray-100 border-gray-300 hover:border-purple-500'}
          ${isDragActive ? 'border-purple-500' : ''}`}
      >
        <input {...getInputProps()} />
        <Upload className={`mx-auto h-12 w-12 ${isDark ? 'text-gray-400' : 'text-gray-500'}`} />
        <p className={`mt-2 ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
          {isDragActive
            ? t.dropFile
            : t.dropFile}
        </p>
      </div>
      {file && (
        <div className={`mt-4 ${isDark ? 'text-gray-300' : 'text-gray-700'}`}>
          {t.selectedFile}: {file.name}
        </div>
      )}
      {error && (
        <div className={`mt-4 text-red-500 ${isDark ? 'text-red-400' : 'text-red-600'}`}>
          {error}
        </div>
      )}
      {isUploading && (
        <div className="mt-4">
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-purple-600 h-2.5 rounded-full transition-all duration-300"
              style={{ width: `${uploadProgress}%` }}
            />
          </div>
          <p className={`text-sm mt-1 text-center ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
            {uploadProgress.toFixed(0)}%
          </p>
        </div>
      )}
      <button
        onClick={handleUpload}
        disabled={!file || isUploading}
        className={`mt-4 px-4 py-2 rounded ${
          file && !isUploading
            ? isDark
              ? 'bg-purple-600 hover:bg-purple-700 text-white'
              : 'bg-purple-500 hover:bg-purple-600 text-white'
            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
        }`}
      >
        {isUploading ? (
          <span className="flex items-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            {t.uploading}
          </span>
        ) : (
          t.upload
        )}
      </button>
    </div>
  );
};

export default FileUpload;
import React from 'react';
import './LunaSpaceLogo.css';

const LunaSpaceLogo: React.FC = () => {
  return (
    <svg width="200" height="50" viewBox="0 0 200 50">
      <defs>
    <linearGradient id="logoGradient" x1="0" y1="0" x2="1" y2="0">
      <stop offset="0%" stop-color="#FF5733" />
      <stop offset="100%" stop-color="#1E90FF" />
    </linearGradient>
  </defs>
      <text x="10" y="35" fontSize="30" fontFamily="Arial, sans-serif" fontWeight="bold" fill="url(#logoGradient)">
        LunaSpace
      </text>
    </svg>
  );
};

export default LunaSpaceLogo;
import React, { useState, useMemo } from 'react';
import { useTheme } from '../ThemeContext';
import Navbar from './Navbar';
import { Dialog, Transition } from '@headlessui/react';
import { useLanguage } from '../LanguageContext';

const Contact: React.FC = () => {
  const { theme } = useTheme();
  const isDark = theme === 'dark';

  const [issueType, setIssueType] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { language } = useLanguage();

  const translations = useMemo(() => ({
    en: {
      title: "Contact Support",
      choice1: "Issue Type",
      placeholder1: "Select an issue type",
      choice2: "Subject",
      placeholder2: "Detail your problem with few words",
      choice3: "Your Message",
      placeholder3: "Describe your problem in detail",
      attachement: "Attachment",
      placeholder4: "Accepted formats: JPG, PNG, PDF (max 5 MB)",
      sendmessage: "Send Message",
      supportrules: "Suport Rules",
      rules1: "Be respectful and courteous when contacting support.",
      rules2: "Provide as much detail as possible about your issue.",
      rules3: "Include any relevant error messages or screenshots.",
      rules4: "Allow up to 24 hours for a response during business days.",
      rules5: "Check our FAQ section before contacting support for common issues.",
      rules6: "Do not share sensitive information like passwords in your message.",
      placeholder11: "Payment Issue",
      placeholder12: "PLACEHOLDER 1 A CHANGER",
      placeholder13: "PLACEHOLDER 2 A CHANGER !!",
      sentmessage: "Message Sent Successfully",
      messageglobal: "Your message has been successfully sent to our support team. We'll get back to you as soon as possible.",
      gotit: "Got it, thanks!"
    },
    fr: {
      title: "Contactez le support",
      choice1: "Type de problème",
      placeholder1: "Sélectionner un type de problème",
      choice2: "Sujet",
      placeholder2: "Détaillez votre problème en quelques mots",
      choice3: "Votre message",
      placeholder3: "Décrivez votre problème en détail",
      attachement: "Pièce jointe",
      placeholder4: "Formats acceptés : JPG, PNG, PDF (max 5 MB)",
      sendmessage: "Envoyer le message",
      supportrules: "Règles du Support",
      rules1: "Soyez respectueux et courtois lorsque vous contactez le service d'assistance.",
      rules2: "Fournissez autant de détails que possible sur votre problème.",
      rules3: "Inclure tout message d'erreur pertinent ou toute capture d'écran.",
      rules4: "Il faut compter jusqu'à 24 heures pour une réponse pendant les jours ouvrables.",
      rules5: "Consultez notre section FAQ avant de contacter le service d'assistance pour les problèmes courants.",
      rules6: "Ne communiquez pas d'informations sensibles telles que des mots de passe dans votre message.",
      placeholder11: "Question vis à vis du paiement",
      placeholder12: "PLACEHOLDER 1 A CHANGER",
      placeholder13: "PLACEHOLDER 2 A CHANGER !!",
      sentmessage: "Message envoyé avec succès",
      messageglobal: "Votre message a été envoyé avec succès à notre équipe d'assistance. Nous vous répondrons dans les plus brefs délais.",
      gotit: "Merci !"
    }
  }), []);
  
  const t = translations[language as keyof typeof translations];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const discordEmbed = {
        embeds: [{
          title: 'Nouveau message de support',
          color: 7506394,
          fields: [
            {
              name: 'Type de problème',
              value: issueType || 'Non spécifié',
              inline: true
            },
            {
              name: 'Sujet',
              value: subject || 'Non spécifié',
              inline: true
            },
            {
              name: 'Message',
              value: message || 'Aucun message',
            }
          ],
          timestamp: new Date().toISOString()
        }]
      };
  
      const response = await fetch('https://discord.com/api/webhooks/1246882393521127515/jTuQFtlWAgXLIBQsMGUcw12ijjyJMIxSUpUSujPQ7UuokOJ2AVxvvKvBGEeTAvhm99r8', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(discordEmbed)
      });
  
      if (!response.ok) {
        throw new Error('Problème lors de l\'envoi du message à Discord');
      }
  
      console.log('Message envoyé à Discord avec succès');
      
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        
        const filePayload = {
          embeds: [{
            title: 'Pièce jointe du message précédent',
            color: 7506394
          }]
        };
        
        formData.append('payload_json', JSON.stringify(filePayload));
  
        const fileResponse = await fetch('https://discord.com/api/webhooks/1246882393521127515/jTuQFtlWAgXLIBQsMGUcw12ijjyJMIxSUpUSujPQ7UuokOJ2AVxvvKvBGEeTAvhm99r8', {
          method: 'POST',
          body: formData
        });
  
        if (!fileResponse.ok) {
          throw new Error('Problème lors de l\'envoi du fichier à Discord');
        }
        
        console.log('Fichier envoyé à Discord avec succès');
      }
      console.log('Form submitted:', { issueType, message });
      setIsModalOpen(true);
      setIssueType('');
      setSubject('');
      setMessage('');
      setFile(null);
    } catch (error) {
      console.error('Erreur lors de l\'envoi:', error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={`min-h-screen flex flex-col ${isDark ? 'color-night bg-gray-900 text-white' : 'color-day bg-gray-100 text-gray-900'}`}>
      <Navbar />
      
      <div className="flex-grow container mx-auto px-4 py-8">
        <h1 className={`text-3xl font-bold mb-6 ${isDark ? 'text-purple-400' : 'text-purple-600'}`}>Contact Support</h1>
        
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex-1">
            <form onSubmit={handleSubmit} className={`${isDark ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg`}>
              <div className="mb-4">
                <label htmlFor="issueType" className="block mb-2 font-medium">{t.choice1}</label>
                <select
                  id="issueType"
                  value={issueType}
                  onChange={(e) => setIssueType(e.target.value)}
                  className={`w-full p-2 rounded ${isDark ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-900'}`}
                  required
                >
                  <option value="">{t.placeholder1}</option>
                  <option value="Payment Issue">{t.placeholder11}</option>
                  <option value="Test">{t.placeholder12}</option>
                  <option value="Bonjour">{t.placeholder13}</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="subject" className="block mb-2 font-medium">{t.choice2}</label>
                <input
                  type="text"
                  id="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder={t.placeholder2}
                  className={`w-full p-2 rounded ${isDark ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-900'}`}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block mb-2 font-medium">{t.choice3}</label>
                <textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder={t.placeholder3}
                  className={`w-full p-2 rounded ${isDark ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-900'}`}
                  rows={4}
                  required
                ></textarea>
              </div>
              <div className="mb-4">
                <label htmlFor="file" className="block mb-2 font-medium">{t.attachement}</label>
                <input
                  type="file"
                  id="file"
                  accept=".jpg,.jpeg,.png,.pdf"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const files = e.target.files;
                    if (files && files.length > 0) {
                      setFile(files[0]);
                    }
                  }}
                  className={`w-full p-2 rounded ${isDark ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-900'} file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:bg-purple-600 file:text-white hover:file:bg-purple-700`}
                />
                <p className="mt-1 text-sm text-gray-500">{t.placeholder4}</p>
              </div>
              <button
                type="submit"
                className="w-full bg-purple-600 text-white py-2 px-4 rounded hover:bg-purple-700 transition-colors"
              >
                {t.sendmessage}
              </button>
            </form>
          </div>

          {/* Support Rules */}
          <div className="flex-1">
            <div className={`${isDark ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg`}>
              <h2 className={`text-xl font-bold mb-4 ${isDark ? 'text-purple-400' : 'text-purple-600'}`}>{t.supportrules}</h2>
              <ul className="list-disc pl-5 space-y-2">
              <li>{t.rules1}</li>
              <li>{t.rules2}</li>
              <li>{t.rules3}</li>
              <li>{t.rules4}</li>
              <li>{t.rules5}</li>
              <li>{t.rules6}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Transition show={isModalOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-md transform overflow-hidden rounded-2xl ${isDark ? 'bg-gray-800' : 'bg-white'} p-6 text-left align-middle shadow-xl transition-all`}>
                  <Dialog.Title
                    as="h3"
                    className={`text-lg font-medium leading-6 ${isDark ? 'text-white' : 'text-gray-900'}`}
                  >
                    {t.sentmessage}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className={`text-sm ${isDark ? 'text-gray-300' : 'text-gray-500'}`}>
                      {t.messageglobal}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className={`inline-flex justify-center rounded-md border border-transparent ${
                        isDark ? 'bg-purple-600 text-white hover:bg-purple-700' : 'bg-purple-100 text-purple-900 hover:bg-purple-200'
                      } px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2`}
                      onClick={closeModal}
                    >
                      {t.gotit}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Contact;